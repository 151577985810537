import { Box, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LaunchIcon from '@mui/icons-material/Launch';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { isChromeExtApp, isSopai } from '../../utils/chromeHelper';
import { SOPAI_WEB_BASE_URL, WEB_BASE_URL } from '../../api/constants';
import { useSnackbar } from '../../context/SnackbarContext';
import { useUserContextController } from '../../context/UserContext';

export interface DocumentationDetailToolbarProps {
  documentationId: string;
  onLaunchClick: () => void;
  onDeleteClick: () => void;
}

const DocumentationDetailToolbar = (props: DocumentationDetailToolbarProps) => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [userContext] = useUserContextController();
  const isManager = userContext?.user?.isManager || false;
  const BASE_URL = isSopai() ? SOPAI_WEB_BASE_URL : WEB_BASE_URL;
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%'
      }}
    >
      <IconButton onClick={() => navigate(-1)}>
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton
        sx={{
          marginLeft: 'auto'
        }}
        onClick={() => {
          navigator.clipboard.writeText(
            `${BASE_URL}/documentation/details/${props.documentationId}`
          );
          showSnackbar('Copied to clipboard');
        }}
      >
        <LinkIcon />
      </IconButton>
      {isManager && !isSopai() && (
        <IconButton onClick={props.onDeleteClick}>
          <DeleteIcon />
        </IconButton>
      )}
      {isChromeExtApp() && (
        <IconButton onClick={props.onLaunchClick}>
          <LaunchIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default DocumentationDetailToolbar;
