import React from 'react';
import { Fab } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';

interface StartAutomationModeFabProps {
  onClick: () => void;
}

const StartAutomationModeFab: React.FC<StartAutomationModeFabProps> = ({ onClick }) => {
  return (
    <Fab
      sx={(theme) => ({
        backgroundColor: theme.customColors.themePrimary,
        color: theme.customColors.themeSecondary,
        position: 'absolute', 
        bottom: 16,
        right: 16,
        '&:hover': {
          backgroundColor: theme.customColors.themePrimary
        }
      })}
      onClick={onClick}
    >
      <SmartToyIcon />
    </Fab>
  );
};

export default StartAutomationModeFab;
