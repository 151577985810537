import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ProfileIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { isChromeExtApp } from '../../utils/chromeHelper';
import LogoutButton from '../logout/LogoutButton';
import { Typography } from '@mui/material';
import { WEB_BASE_URL } from '../../api/constants';
import { ExtensionName, getExtensionName } from '../../utils/ManifestChecker';
import { useCookies } from 'react-cookie';
import { useSnackbar } from '../../context/SnackbarContext';

interface ProfileNavigationsProps {
  isWebApp: boolean;
}

const ProfileNavigations = (props: ProfileNavigationsProps) => {
  const [, , removeCookie] = useCookies(['user_session_token']);
  const { showSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const extensionName = (props.isWebApp) ? null : getExtensionName();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'borderBox'
      }}
    >
      { extensionName !== ExtensionName.SOPAI && (
        <>
          <IconButton
            onClick={handleClick}
            sx={{
              width: '40px',
              height: '40px',
              alignContent: 'center',
              padding: '0'
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <ProfileIcon
              sx={{
                alignSelf: 'center',
                height: '35px',
                width: '35px',
                margin: 'auto',
                ':hover': {
                  cursor: 'pointer'
                }
              }}
            />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            sx={{
              '& .MuiList-root': {
                paddingTop: '2px !important',
                paddingBottom: '2px !important',
                minWidth: '200px'
              }
            }}
          >
            {isChromeExtApp() && (
              <MenuItem
                key="profile"
                sx={{ padding: '0px' }}
                onClick={() => {
                  window.open(WEB_BASE_URL, '_blank');
                  handleCloseMenu();
                }}
              >
                <Typography
                  sx={{
                    width: '100%',
                    padding: '10px 20px',
                    fontSize: '14px'
                  }}
                >
                  Open Web Application
                </Typography>
              </MenuItem>
            )}
            <MenuItem key="logout" sx={{ padding: '0px' }}>
              <LogoutButton />
            </MenuItem>
          </Menu>
        </>
      )}
      { extensionName === ExtensionName.SOPAI && (
        <IconButton
          onClick={() => {
            removeCookie('user_session_token', {
              path: '/'
            });
            showSnackbar('User logged out, redirecting to login page', 'success');
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }}
          sx={{
            width: '40px',
            height: '40px',
            alignContent: 'center',
            padding: '0'
          }}
        >
          <LogoutIcon
            sx={{
              alignSelf: 'center',
              height: '24px',
              width: '24px',
              margin: 'auto',
              ':hover': {
                cursor: 'pointer'
              }
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default ProfileNavigations;
