import React, { useState } from 'react';
import { Fab } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';

interface DocAutomationFabProps {
  state?: AutomationStatus;
  onPlayClick: () => void;
  onPauseClick: () => void;
  onStopClick: () => void;
}

export enum AutomationStatus {
  PLAY = 'play',
  PAUSE = 'pause',
  STOP = 'stop'
}

const DocAutomationFab: React.FC<DocAutomationFabProps> = (
  props: DocAutomationFabProps
) => {
  const [internalAutomationState, setInternalAutomationState] =
    useState<AutomationStatus>(AutomationStatus.STOP);
  const automationState = props.state || internalAutomationState;

  const getIcon = () => {
    switch (automationState) {
      case AutomationStatus.PLAY:
        return <StopIcon />;
      case AutomationStatus.PAUSE:
        return <StopIcon />;
      case AutomationStatus.STOP:
        return <PlayArrowIcon />;
      default:
        return <PlayArrowIcon />;
    }
  };

  return (
    <Fab
      sx={(theme) => ({
        backgroundColor: theme.customColors.themePrimary,
        color: theme.customColors.themeSecondary,
        position: 'absolute',
        bottom: 16,
        right: 16,
        '&:hover': {
          backgroundColor: theme.customColors.themePrimary
        }
      })}
      onClick={() => {
        switch (automationState) {
          case AutomationStatus.PLAY:
            if (!props.state) {
              setInternalAutomationState(AutomationStatus.STOP);
            }
            props.onStopClick();
            break;
          case AutomationStatus.PAUSE:
            if (!props.state) {
              setInternalAutomationState(AutomationStatus.STOP);
            }
            props.onStopClick();
            break;
          case AutomationStatus.STOP:
            if (!props.state) {
              setInternalAutomationState(AutomationStatus.PLAY);
            }
            props.onPlayClick();
            break;
          default:
            break;
        }
      }}
    >
      {getIcon()}
    </Fab>
  );
};

export default DocAutomationFab;
