import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    customColors: {
      themePrimary: string;
      themeSecondary: string;
      themeTertiary: string;
      themeDanger: string;
      themeSuccess: string;
      themeEmojiYellow: string;
      themeNeutralPrimaryColor: string;
      themeNeutralSecondaryColor: string;
    };
  }
  interface ThemeOptions {
    customColors: {
      themePrimary: string;
      themeSecondary: string;
      themeTertiary: string;
      themeDanger: string;
      themeSuccess: string;
      themeEmojiYellow: string;
      themeNeutralPrimaryColor: string;
      themeNeutralSecondaryColor: string;
    };
  }
};

const sopaiTheme = createTheme({
  customColors: {
    themePrimary: '#777777',
    themeSecondary: '#F1F3F4',
    themeTertiary: '#CCCCCC',
    themeDanger: '#DC3545',
    themeSuccess: '#28a745',
    themeEmojiYellow: "#FBDC34",
    themeNeutralPrimaryColor: '#000000',
    themeNeutralSecondaryColor: '#AAAAAA',
  }
});

const dokaiTheme = createTheme({
  customColors: {
    themePrimary: '#49BAFD',
    themeSecondary: '#FFFFFF',
    themeTertiary: '#F1F3F4',
    themeDanger: '#DC3545',
    themeSuccess: '#28a745',
    themeEmojiYellow: "#FBDC34",
    themeNeutralPrimaryColor: '#000000',
    themeNeutralSecondaryColor: '#AAAAAA',
  }
})

export { sopaiTheme, dokaiTheme };
