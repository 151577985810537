import { ExtensionName, getExtensionName } from './ManifestChecker';

export const isChromeExtApp = () => {
  return (
    typeof chrome !== 'undefined' && typeof chrome.identity !== 'undefined'
  );
};

export const isSopai = () => {
  const extensionName = !isChromeExtApp() ? null : getExtensionName();
  const isSopai = !isChromeExtApp()
    ? process.env.REACT_APP_APPBUILD === ExtensionName.SOPAI
    : extensionName === ExtensionName.SOPAI;

  return isSopai;
};
