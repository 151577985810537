import './styles/App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Box from '@mui/material/Box';
import Layout from './components/layout';
import DocumentationPage from './pages/documentation/details/';
import NotFoundPage from "./pages/not-found"; // 404 page

function App() {
  // Todo: set global margin in constant file
  return (
    <ThemeProvider>
      <Box
        sx={(theme) => ({
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          height: '100vh',
          overflow: 'hidden',
          backgroundColor: theme.customColors.themeTertiary,
        })}
      >
        <Router>
          <Routes>
            <Route element={<Layout isWebApp />}>
              {/* If home and login is to be added to SOPAI web at any given point of time, please check the home and login component code and change the way extensionName check is done. */}
              <Route
                path="/documentation/details/:documentId"
                element={<DocumentationPage isWebApp />}
              />
              {/* Catch-all route to redirect to root */}
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
