import { Box } from '@mui/material';
import { useRef } from 'react';

import RecordedActionCard from '../learnMode/RecordedActionCard';
import { RecordedAction } from '../../pages/teach/recordedAction';
import interactiveGuidanceForStep from './interactiveGuidance';
import { useSnackbar } from '../../context/SnackbarContext';

interface StepByStepListProps {
  steps: RecordedAction[];
  interactiveGuidanceStep: number;
  setInteractiveGuidanceStep: (step: number) => void;
  sx?: object;
  disableHoverListener?: boolean;
  enableInteractiveGuidance?: boolean;
}

const StepByStepList = (props: StepByStepListProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { showSnackbar } = useSnackbar();

  const scrollToCard = (idx: number) => {
    if (scrollRef.current) {
      const cards = scrollRef.current.children;
      if (cards[idx]) {
        cards[idx].scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  };

  scrollToCard(props.interactiveGuidanceStep);

  return (
    <Box
      sx={{
        py: 2,
        borderBottom: '1px solid #e0e0e0',
        ...props.sx
      }}
    >
      <Box
        ref={scrollRef}
        sx={{
          maxHeight: '200px',
          overflowY: 'auto',
          scrollbarWidth: 'thin'
          // px: 10,
        }}
      >
        {props.steps.map((step, idx) => (
          <RecordedActionCard
            idx={idx}
            interactiveGuidanceStep={props.interactiveGuidanceStep}
            recordedAction={step}
            showScreenshot={false}
            disableHoverListener={props.disableHoverListener}
            onClick={() => {
              if (props.enableInteractiveGuidance) {
                props.setInteractiveGuidanceStep(idx);
                interactiveGuidanceForStep(step);
              } else {
                showSnackbar(
                  'Interactive guidance is only available in Extension',
                  'error'
                );
              }
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default StepByStepList;
