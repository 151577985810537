import Box from '@mui/material/Box';
import ProfileNavigations from './ProfileNavigations';
import AppNavigations from './AppNavigations';

interface SidebarComponentProps {
  isWebApp: boolean;
  isManager: boolean;
  hasAccessToAutomation: boolean;
}

const SidebarComponent = (props: SidebarComponentProps) => {
  return (
    <Box
      sx={{
        marginTop: '10px',
        paddingTop: '10px',
        width: '50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxSizing: 'border-box'
      }}
    >
      <AppNavigations
        isWebApp={props.isWebApp}
        isManager={props.isManager}
        hasAccessToAutomation={props.hasAccessToAutomation}
      />
      <ProfileNavigations
        isWebApp={props.isWebApp}
      />
    </Box>
  );
};

export default SidebarComponent;
