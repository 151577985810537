import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Tooltip,
  Typography
} from '@mui/material';
import { RecordedAction } from '../../pages/teach/recordedAction';
import { useEffect, useState } from 'react';
import { zoomScreenshot } from '../../utils/image';

interface RecordedActionCardProps {
  idx: number;
  recordedAction: RecordedAction;
  onClick: () => void;
  interactiveGuidanceStep?: number;
  showScreenshot: boolean;
  disableHoverListener?: boolean;
}

const RecordedActionCard = (props: RecordedActionCardProps) => {
  const { idx, recordedAction, interactiveGuidanceStep, showScreenshot } =
    props;

  const [processedScreenshotUrl, setProcessedScreenshotUrl] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (showScreenshot && recordedAction.screenshotUrl) {
      zoomScreenshot(
        recordedAction.screenshotUrl,
        recordedAction.targetBoundingRect,
        setProcessedScreenshotUrl
      );
    }
  }, [
    recordedAction.screenshotUrl,
    recordedAction.targetBoundingRect,
    showScreenshot
  ]);

  return (
    <Tooltip
      title={recordedAction.description}
      placement="top"
      disableHoverListener={props.disableHoverListener}
    >
      <Card
        variant="outlined"
        sx={(theme) => ({
          my: 1,
          backgroundColor:
            interactiveGuidanceStep !== undefined &&
            interactiveGuidanceStep >= idx
              ? theme.customColors.themeNeutralSecondaryColor
              : theme.customColors.themeSecondary,
          borderColor:
            interactiveGuidanceStep !== undefined &&
            interactiveGuidanceStep >= idx
              ? theme.customColors.themeNeutralSecondaryColor
              : theme.customColors.themePrimary,
          borderWidth: '1px',
          borderRadius: '10px'
        })}
        onClick={props.onClick}
      >
        <CardActionArea>
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Avatar
                sx={(theme) => ({
                  width: 24,
                  height: 24,
                  fontSize: 14,
                  backgroundColor: theme.customColors.themePrimary,
                  marginRight: 2
                })}
              >
                {idx}
              </Avatar>
              {recordedAction.description.length > 40
                ? recordedAction.description.slice(0, 40) + '...'
                : recordedAction.description}
            </Typography>
            {showScreenshot && processedScreenshotUrl && (
              <img
                style={{
                  maxWidth: '100%',
                  marginTop: '10px'
                }}
                alt={`step-${idx + 1}`}
                src={processedScreenshotUrl}
              />
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

export default RecordedActionCard;
