import React, { createContext, useMemo, ReactNode } from 'react';
import { ThemeProvider as MuiThemeProvider, Theme } from '@mui/material/styles';
import { dokaiTheme, sopaiTheme } from '../theme';
import { isChromeExtApp } from '../utils/chromeHelper';
import { ExtensionName, getExtensionName } from '../utils/ManifestChecker';

// const theme = useTheme(); to access theme object in component.
// In MUI components, can access via sx={(theme) => ({})}

/**
 * Toggle Theme is kept here but is unused
 * Can add value = {{toggleTheme}} to ThemeContext if light/dark mode is implemented
 * 
 * Add to theme context type:
 * toggleTheme: () => void;
 * 
 * Add to ThemeContext
 *   toggleTheme: () => {}
 */
interface ThemeContextType {}

const ThemeContext = createContext<ThemeContextType>({});

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  // const [darkMode, setDarkMode] = useState(false);
  const extensionName = (!isChromeExtApp()) ? null : getExtensionName();
  const isSopai = (!isChromeExtApp())
    ? process.env.APP_BUILD === ExtensionName.SOPAI
    : extensionName === ExtensionName.SOPAI;

  // const toggleTheme = () => {
  //   setDarkMode((prevMode) => !prevMode);
  // };

  const theme: Theme = useMemo(
    () => {
      return isSopai ? sopaiTheme : dokaiTheme;
    },
    [isSopai]
  );

  return (
    <ThemeContext.Provider value>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };
