import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AppSopai from './App-Web-Sopai';

import { MessageContextProvider } from './context/MessageContext';
import { UserContextProvider } from './context/UserContext';

import { BugsnagErrorBoundary } from './utils/bugsnag';
import ErrorFallback from './components/ErrorComponent';
import { SnackbarProvider } from './context/SnackbarContext';
import { isSopai } from './utils/chromeHelper';

const root = document.createElement('div');
root.className = 'container';
document.body.appendChild(root);
const rootDiv = ReactDOM.createRoot(root);
if (BugsnagErrorBoundary) {
  rootDiv.render(
    <React.StrictMode>
      <BugsnagErrorBoundary FallbackComponent={ErrorFallback}>
        <SnackbarProvider>
          <UserContextProvider>
            <MessageContextProvider>
              {isSopai() ? <AppSopai /> : <App />}
            </MessageContextProvider>
          </UserContextProvider>
        </SnackbarProvider>
      </BugsnagErrorBoundary>
    </React.StrictMode>
  );
}
