import { BASE_URL } from './constants';
import { Cookies } from 'react-cookie';

const getUserSessionCookie = () => {
  const cookies = new Cookies();
  return cookies.get('user_session_token');
};

export async function createAutomationJob(
  job_description: string
): Promise<any> {
  const url = `${BASE_URL}/automation/create`;
  const userSessionToken = getUserSessionCookie();
  const requestData = {
    job_description,
    extra_data: {}
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating automation job:', error);
    throw error;
  }
}

export async function getAutomationJob(jobId: string): Promise<any> {
  const url = `${BASE_URL}/automation/${jobId}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error getting automation job:', error);
    throw error;
  }
}

export async function getAutomationJobs(
  offset: number,
  limit: number
): Promise<any> {
  const url = `${BASE_URL}/automation?offset=${offset}&limit=${limit}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    return await response.json();
  } catch (error) {
    console.error('Error getting automation jobs:', error);
    throw error;
  }
}
