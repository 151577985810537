import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getAutomationJob } from '../../api/automationAgent';
import { AutomationJob } from '../../types/automation';
import { statusStyles } from '../../utils/automation';

const AutomationJobDetail = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState<AutomationJob | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAutomationJob(jobId || '')
      .then((response) => {
        setJob(response);
      })
      .catch((error) => {
        console.error('Error fetching job details:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [jobId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          bgcolor: '#f5f5f5'
        }}
      >
        <CircularProgress sx={{ color: '#2196f3' }} />
      </Box>
    );
  }

  if (!job) {
    return (
      <Box sx={{ p: 3, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
        <Typography variant="h6" color="error">
          Job not found
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        maxWidth: '800px',
        margin: '0 auto',
        minHeight: '100vh'
      }}
    >
      <Box sx={{ mb: 3 }}>
        <Link
          to="/automation"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: '#666',
              '&:hover': { color: '#1976d2' }
            }}
          >
            <ArrowBackIcon fontSize="small" />
            Back to Automation Jobs
          </Typography>
        </Link>
      </Box>

      <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
        Automation Job Details
      </Typography>
      <Box
        sx={{
          mb: 3,
          p: 2,
          borderRadius: 1,
          '&:hover': { bgcolor: '#f8f9fa' }
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: '#666', mb: 1, fontWeight: 600 }}
        >
          Job ID
        </Typography>
        <Typography sx={{ color: '#2c3e50', fontSize: '1.1rem' }}>
          {job.id}
        </Typography>
      </Box>

      <Box
        sx={{
          mb: 3,
          p: 2,
          borderRadius: 1,
          '&:hover': { bgcolor: '#f8f9fa' }
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: '#666', mb: 1, fontWeight: 600 }}
        >
          Description
        </Typography>
        <Typography sx={{ color: '#2c3e50', fontSize: '1.1rem' }}>
          {job.job_description}
        </Typography>
      </Box>

      <Box
        sx={{
          mb: 3,
          p: 2,
          borderRadius: 1,
          '&:hover': { bgcolor: '#f8f9fa' },
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          flexWrap: 'wrap'
        }}
      >
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ color: '#666', mb: 1, fontWeight: 600 }}
          >
            Status
          </Typography>
          <Typography
            sx={{
              display: 'inline-block',
              px: 2,
              py: 0.5,
              borderRadius: 1,
              fontSize: '0.9rem',
              fontWeight: 500,
              ...statusStyles[job.status]
            }}
          >
            {job.status}
          </Typography>
        </Box>

        {job.extra_data?.number_of_actions && (
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ color: '#666', mb: 1, fontWeight: 600 }}
            >
              Total Actions
            </Typography>
            <Typography sx={{ color: '#2c3e50', fontSize: '1.1rem' }}>
              {job.extra_data.number_of_actions}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          mb: 3,
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap'
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 1,
            flex: 1,
            minWidth: '250px',
            '&:hover': { bgcolor: '#f8f9fa' }
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: '#666', mb: 1, fontWeight: 600 }}
          >
            Created At
          </Typography>
          <Typography sx={{ color: '#2c3e50', fontSize: '1.1rem' }}>
            {new Date(job.create_ts).toLocaleString()}
          </Typography>
        </Box>

        <Box
          sx={{
            p: 2,
            borderRadius: 1,
            flex: 1,
            minWidth: '250px',
            '&:hover': { bgcolor: '#f8f9fa' }
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: '#666', mb: 1, fontWeight: 600 }}
          >
            Last Updated
          </Typography>
          <Typography sx={{ color: '#2c3e50', fontSize: '1.1rem' }}>
            {new Date(job.create_ts).toLocaleString()}
          </Typography>
        </Box>
      </Box>

      {job.extra_data?.output && (
        <Box
          sx={{
            mb: 3,
            p: 2,
            borderRadius: 1,
            bgcolor: '#e8f5e9',
            '&:hover': { bgcolor: '#e0f2f1' }
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: '#1b5e20', mb: 1, fontWeight: 600 }}
          >
            Result
          </Typography>
          <Typography sx={{ color: '#2c3e50', fontSize: '1.1rem' }}>
            {job.extra_data?.output}
          </Typography>
        </Box>
      )}

      {/* {job.error && (
        <Box
          sx={{
            mb: 3,
            p: 2,
            borderRadius: 1,
            bgcolor: '#ffebee',
            '&:hover': { bgcolor: '#fff3f3' }
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ color: '#d32f2f', mb: 1, fontWeight: 600 }}
          >
            Error
          </Typography>
          <Typography
            sx={{ color: '#2c3e50', fontSize: '1.1rem' }}
            color="error"
          >
            {job.error}
          </Typography>
        </Box>
      )} */}
    </Box>
  );
};

export default AutomationJobDetail;
