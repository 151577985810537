import { Box } from '@mui/material';
import { SOPAI_WEB_BASE_URL, WEB_BASE_URL } from '../../../api/constants';
import { isSopai } from '../../../utils/chromeHelper';
interface audioPlayerProps {
  audioURL: string;
}

const AudioPlayer = (props: audioPlayerProps) => {
  const BASE_URL = isSopai() ? SOPAI_WEB_BASE_URL : WEB_BASE_URL;
  const audioId = props.audioURL.split(`blob:${BASE_URL}/`).join('');
  return (
    <Box sx={{ width: '100%' }}>
      <audio
        id={`audio-${audioId}`}
        style={{ width: '100%' }}
        controls
        key={props.audioURL}
      >
        <source src={props.audioURL} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </Box>
  );
};

export default AudioPlayer;
