import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { SLACK_AUTH_BASE_URL, SLACK_USER_SCOPE } from '../../api/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getAccessToken } from '../../api/slack';
import { GOOGLE_ACCOUNT_TYPE } from '../../types/userCredentials';
import {
  setSlackCredentials,
  useUserContextController
} from '../../context/UserContext';
import { setCredentials } from '../../api/userProfile';
import { useSnackbar } from '../../context/SnackbarContext';
import { useState } from 'react';
import SlackLogo from '../../assets/SlackLogo.svg';
import Bugsnag from '@bugsnag/js';
import { URL_PATHNAMES } from '../layout';

interface SlackAuthComponentProps {
  slackAuthToken: string | undefined;
  userId: string;
}

// TODO: Move to types (Rename to SlackEnumMessageType)
enum EnumMessageType {
  SUCCESS = 'SUCCESS',
  FAILED_CREDENTIALS = 'FAILED_CREDENTIALS',
  FAILED_AUTH = 'FAILED_AUTH',
  PREEMPTIVE = 'PREEMPTIVE'
}
const linkAccountSnackbarMessages = {
  [EnumMessageType.SUCCESS]: 'Login to Slack successful',
  [EnumMessageType.FAILED_CREDENTIALS]: 'Failed to link Slack account',
  [EnumMessageType.FAILED_AUTH]: 'Failed to link Slack account',
  [EnumMessageType.PREEMPTIVE]: 'Failed to link Slack account'
};

// TODO: Implement token revocation to remove from credentials
const SlackAuthComponent = (props: SlackAuthComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [, userDispatch] = useUserContextController();
  const { showSnackbar } = useSnackbar();
  const messageSettings = linkAccountSnackbarMessages;

  const onSignIn = (token: string) => {
    if (token) {
      const accountType = GOOGLE_ACCOUNT_TYPE.WEB_APP;
      // NOTE: Slack endpoint does not return refresh_token (for now, can be configured in the future)
      const userCredentials = {
        user_id: String(props.userId),
        credentials: {
          slack: {
            access_token: token
          }
        }
      };
      setCredentials(accountType, userCredentials)
        .then(() => {
          showSnackbar(messageSettings.SUCCESS, 'success');
          setSlackCredentials(userDispatch, {
            access_token: token
          });
        })
        .catch(() => {
          // Will come in here upon failing to set credentials on our backend
          Bugsnag.notify(
            'Credentials Error: ' + messageSettings.FAILED_CREDENTIALS
          );
          showSnackbar(messageSettings.FAILED_CREDENTIALS, 'error');
          setLoading(false);
        });
    } else {
      // On prod, will come in this block if pressing 'Cancel' or 'Return to Dokai'
      Bugsnag.notify('Auth Error: ' + messageSettings.FAILED_AUTH);
      showSnackbar(messageSettings.FAILED_AUTH, 'error');
      setLoading(false);
    }
  };

  const onClick = () => {
    setLoading(true);
    const REDIRECT_URI = `${window.location.origin}${URL_PATHNAMES.HOME}`;
    // Use this for testing, get the code, inject the code directly
    // "https://patia-web-126f2619c837.herokuapp.com/";

    const publicToken = process.env.REACT_APP_SLACK_PUBLIC_TOKEN;
    const url = `${SLACK_AUTH_BASE_URL}?client_id=${publicToken}&user_scope=${SLACK_USER_SCOPE}&redirect_uri=${REDIRECT_URI}`;

    const extractCode = (url: string) => {
      const urlParams = new URLSearchParams(new URL(url).search);
      return urlParams.get('code') || '';
    };

    const popup = window.open(url, 'popup', 'popup=true');
    const checkPopup = setInterval(async () => {
      let popupClosedPreemptively = true;
      try {
        if (
          popup &&
          popup.window.location.href.includes(window.location.origin)
        ) {
          const code = extractCode(popup.location.href);
          const accessToken = await getAccessToken(code, REDIRECT_URI);
          popupClosedPreemptively = false;
          onSignIn(accessToken);
          popup.close();
        }
      } catch (error) {
        // Entering this error is expected while the url is in the unexpected loc
      }
      if (!popup || !popup.closed) return;
      clearInterval(checkPopup);
      // Set loading false and show snackbar but does not throw error on bugsnag
      if (popupClosedPreemptively) {
        setLoading(false);
        showSnackbar(messageSettings.PREEMPTIVE, 'error');
      }
    }, 1000);
  };

  return (
    <Box
      sx={(theme) => ({
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        border: `1px solid ${theme.customColors.themeNeutralSecondaryColor}`,
        justifyContent: 'space-between',
        marginBottom: '20px',
        borderRadius: '8px',
        width: '100%',
        padding: '20px 16px',
        textAlign: 'left',
        textTransform: 'capitalize',
        color: theme.customColors.themeNeutralPrimaryColor
      })}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start'
        }}
      >
        <img
          alt="slack-logo"
          src={SlackLogo}
          style={{
            height: '20px',
            width: '20px',
            marginRight: '10px',
            fontSize: '20px',
            alignSelf: 'center'
          }}
        />
        <Typography
          sx={{
            alignSelf: 'center',
            fontWeight: '600'
          }}
        >
          Slack
        </Typography>
        {/* Show check mark if auth token exists */}
        {props.slackAuthToken && (
          <CheckCircleIcon
            sx={(theme) => ({
              marginLeft: '4px',
              width: '16px',
              height: '16px',
              color: theme.customColors.themeSuccess,
              alignSelf: 'center'
            })}
          />
        )}
      </Box>
      {!props.slackAuthToken && (
        <Button
          sx={(theme) => ({
            boxSizing: 'border-box',
            padding: '0',
            textAlign: 'left',
            textTransform: 'capitalize',
            fontWeight: '600',
            fontSize: '12px',
            color: theme.customColors.themePrimary,
            ':hover': {
              background: 'none'
            }
          })}
          onClick={onClick}
          disabled={loading}
          endIcon={loading ? <CircularProgress size={12} /> : null}
        >
          Link Account
        </Button>
      )}
    </Box>
  );
};

export default SlackAuthComponent;
