export interface UserCredentials {
  user_id: string;
  credentials: Record<string, any>;
}

export enum GOOGLE_ACCOUNT_TYPE {
  EXTENSION = 'google_ext',
  WEB_APP = 'google_web',
  SOPAI_EXTENSION = 'sopai_google_ext',
  SOPAI_WEB_APP = 'sopai_google_web'
}
