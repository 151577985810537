import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { RecordedAction } from './recordedAction';
import { Button, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface EndRecordingModalProps {
  index: number;
  open: boolean;
  recordedAction: RecordedAction;
  onClose: (recordedAction: RecordedAction) => void;
  handleDelete?: (index: number) => void;
  editMode: boolean;
  hasPrevious?: boolean;
  onPrevious?: () => void;
  hasNext?: boolean;
  onNext?: () => void;
}

const RecordingStepDetailModal = (props: EndRecordingModalProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [stepContext, setStepContext] = useState(
    props.recordedAction.description
  );

  const deleteStep = () => {
    if (props.handleDelete) {
      props.handleDelete(props.index);
    }
  };

  // Add keyboard event handler
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft' && props.hasPrevious && props.onPrevious) {
      props.onPrevious();
    }
    if (event.key === 'ArrowRight' && props.hasNext && props.onNext) {
      props.onNext();
    }
  };

  // Add effect to manage event listeners
  useEffect(() => {
    if (props.open) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [props.open, props.index]);

  // TODO: add max height and scrollable for long text
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.onClose({
          ...props.recordedAction,
          description: stepContext
        });
      }}
    >
      <Box sx={(theme) => ({
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '600px',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        bgcolor: theme.customColors.themeTertiary,
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        wrap: 'wrap'
      })}>
        <Button
          disabled={!props.hasPrevious}
          onClick={props.onPrevious}
          sx={(theme) => ({
            minWidth: 'auto',
            backgroundColor: theme.customColors.themeTertiary,
            '&:hover': {
              backgroundColor: 'action.hover'
            }
          })}
        >
          <NavigateBeforeIcon />
        </Button>
        <Box>
          <Typography variant="h6">Step description:</Typography>
          {props.editMode ? (
            <TextField
              fullWidth
              defaultValue={props.recordedAction.description}
              id="outlined-multiline-flexible"
              multiline
              maxRows={4}
              sx={{
                marginBottom: 2
              }}
              onChange={(e) => setStepContext(e.target.value)}
            />
          ) : (
            <Typography>{props.recordedAction.description}</Typography>
          )}

          {props.recordedAction.screenshotUrl && (
            <Box>
              <Typography
                sx={{
                  marginTop: '10px'
                }}
                variant="h6"
              >
                Screenshot:
              </Typography>
              <img
                alt={`step-${props.index}-screenshot`}
                style={{
                  maxWidth: '100%'
                }}
                src={props.recordedAction.screenshotUrl}
              />
            </Box>
          )}
          {/* Only show delete button if handleDelete is provided as props */}
          {props.handleDelete && (
            <>
              {confirmDelete ? (
                <>
                  <Button
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    color="inherit"
                    onClick={() => setConfirmDelete(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    color="error"
                    onClick={deleteStep}
                  >
                    Confirm
                  </Button>
                </>
              ) : (
                <Button
                  sx={{
                    textTransform: 'capitalize'
                  }}
                  color="error"
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete Step
                </Button>
              )}
            </>
          )}
        </Box>
        <Button
          disabled={!props.hasNext}
          onClick={props.onNext}
          sx={(theme) => ({
            minWidth: 'auto',
            backgroundColor: theme.customColors.themeTertiary,
            '&:hover': {
              backgroundColor: 'action.hover'
            }
          })}
        >
          <NavigateNextIcon />
        </Button>
      </Box>
    </Modal>
  );
};

export default RecordingStepDetailModal;
