import React from "react";
import { Typography, Container } from "@mui/material";

const NotFoundPage: React.FC = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Typography variant="h1" fontWeight="bold" gutterBottom>
        404
      </Typography>
      <Typography variant="h5" color="textSecondary" gutterBottom>
        Oops! The page you are looking for does not exist.
      </Typography>
      <Typography variant="body1" color="textSecondary" mb={4}>
        It might have been moved or deleted. Check the URL and try again.
      </Typography>
    </Container>
  );
};

export default NotFoundPage;
