import React, { useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Tooltip
} from '@mui/material';
import { getChannelIds } from '../../api/slack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ingestDocument } from '../../api/patiaAI';
import Bugsnag from '@bugsnag/js';
import { useSnackbar } from '../../context/SnackbarContext';

interface SlackChannelPickerProps {
  slackAccessToken: string | undefined;
  agentName: string;
}

const SlackChannelPicker = (props: SlackChannelPickerProps) => {
  // TODO: Error handling
  const [open, setOpen] = useState(false);
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [onSubmitProgress, setOnSubmitProgress] = useState(false);
  const { showSnackbar } = useSnackbar();
  const slackAccessToken = props.slackAccessToken;

  if (slackAccessToken) {
    const handleOpen = () => {
      setError(null);
      setOnSubmitProgress(false);
      setOpen(true);
      fetchChannels();
    };

    const handleClose = () => {
      setError(null);
      setOnSubmitProgress(false);
      setOpen(false);
      resetForm();
    };

    const resetForm = () => {
      setSelectedChannels([]);
    };

    const fetchChannels = async () => {
      setLoadingData(true);
      setError(null);
      getChannelIds(slackAccessToken)
        .then((response) => {
          setChannels(response);
          setLoadingData(false);
        })
        .catch((error) => {
          Bugsnag.notify(error);
          setError('Error loading Slack channel list');
        });
    };

    const handleChannelChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const channelId = event.target.value;
      setSelectedChannels((prev) =>
        prev.includes(channelId)
          ? prev.filter((id) => id !== channelId)
          : [...prev, channelId]
      );
    };

    const handleApply = () => {
      setOnSubmitProgress(true);
      const selectedChannelsMetadata = channels.filter((channel: any) =>
        selectedChannels.includes(channel.id)
      );
      ingestDocument(
        props.agentName,
        selectedChannels,
        'slack_channels',
        slackAccessToken,
        selectedChannelsMetadata
      )
        .then((response) => {
          showSnackbar(response.message, 'success');
          setOnSubmitProgress(false);
          handleClose();
        })
        .catch((error) => {
          // TODO: show snackbar and close modal upon failure instead of showing error on component to align with google doc ingestion
          setOnSubmitProgress(false);
          Bugsnag.notify(error);
          setError('Error ingesting Slack channels');
        });
    };

    return (
      <Box>
        <Button
          sx={(theme) => ({
            border: `1px solid ${theme.customColors.themeNeutralSecondaryColor}`,
            justifyContent: 'space-between',
            marginBottom: '20px',
            borderRadius: '8px',
            width: '100%',
            padding: '10px 16px',
            textAlign: 'left',
            textTransform: 'capitalize',
            color: theme.customColors.themePrimary
          })}
          onClick={handleOpen}
        >
          Pick a Channel from Slack
          <ChevronRightIcon
            sx={(theme) => ({
              color: theme.customColors.themeNeutralSecondaryColor
            })}
          />
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box sx={(theme) => ({ 
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            maxHeight: 400,
            scrollbarWidth: 'none',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none' // For Chrome, Safari, and Edge
            },
            bgcolor: theme.customColors.themeTertiary,
            border: `2px solid ${theme.customColors.themeNeutralPrimaryColor}`,
            boxShadow: 24,
            p: 4
          })}>
            <Typography variant="h6" component="h2">
              Select Channels
            </Typography>
            {loadingData && <CircularProgress />}
            {error && <Typography sx={(theme) => ({ color: theme.customColors.themeDanger })}>{error}</Typography>}
            {onSubmitProgress && (
              <Typography>
                Ingesting documents... This might take a while
              </Typography>
            )}
            {!loadingData && !onSubmitProgress && !error && (
              <FormGroup>
                {/* Create a type for the response */}
                {channels.map((channel: any) => (
                  <FormControlLabel
                    key={channel.id}
                    control={
                      <Checkbox
                        checked={selectedChannels.includes(channel.id)}
                        onChange={handleChannelChange}
                        value={channel.id}
                      />
                    }
                    label={channel.name}
                  />
                ))}
              </FormGroup>
            )}
            {channels.length > 0 && selectedChannels.length > 0 && !error && (
              <Button
                endIcon={
                  onSubmitProgress ? <CircularProgress size={20} /> : null
                }
                variant="contained"
                sx={(theme) => ({
                  backgroundColor: theme.customColors.themePrimary
                })}
                disabled={onSubmitProgress}
                onClick={handleApply}
              >
                Apply
              </Button>
            )}
          </Box>
        </Modal>
      </Box>
    );
  } else {
    return (
      <Box>
        <Tooltip title="Link account to start ingesting channels from slack">
          <Button
            sx={(theme) => ({
              border: `1px solid ${theme.customColors.themeNeutralSecondaryColor}`,
              justifyContent: 'space-between',
              marginBottom: '20px',
              borderRadius: '8px',
              width: '100%',
              padding: '10px 16px',
              textAlign: 'left',
              textTransform: 'capitalize',
              color: theme.customColors.themeNeutralSecondaryColor,
              ':hover': {
                backgroundColor: 'inherit',
                cursor: 'not-allowed'
              }
            })}
          >
            Pick a Channel from Slack
            <ChevronRightIcon
              sx={(theme) => ({
                color: theme.customColors.themeNeutralSecondaryColor
              })}
            />
          </Button>
        </Tooltip>
      </Box>
    );
  }
};

export default SlackChannelPicker;
