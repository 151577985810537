export enum ExtensionName {
  DOKAI = 'DOKAI',
  SOPAI = 'SOPAI'
}

export const getExtensionName = () => {
  const manifest = chrome.runtime.getManifest();
  const extensionName = manifest.name;

  if (extensionName === 'Dokai BETA') {
    return ExtensionName.DOKAI;
  } else if (extensionName === 'SOPAI') {
    return ExtensionName.SOPAI;
  }
};
