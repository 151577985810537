import React from 'react';
import { Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SlackAuthComponent from '../../components/login/SlackAuthComponent';
import NotionAuthComponent from '../../components/login/NotionAuthComponent';
import { useUserContextController } from '../../context/UserContext';
import { Typography } from '@mui/material';
import DocumentIngestion from './document-ingestion';

const ProfilePage: React.FC = () => {
  const [userContext] = useUserContextController();
  const { user, slackCredentials, notionCredentials } = userContext;

  if (!user) {
    return <Navigate to="home" replace />;
  }

  const ProfileSection = () => (
    <Box
      sx={{
        width: '100%',
        marginBottom: '50px'
      }}
    >
      <Typography
        sx={{
          maxWidth: '100%',
          wordWrap: 'break-word',
          fontWeight: '600',
          fontSize: '26px',
          marginBottom: '20px'
        }}
      >
        Profile
      </Typography>
      <Box
        sx={{
          marginBottom: '20px'
        }}
      >
        <Typography
          sx={{
            maxWidth: '100%',
            wordWrap: 'break-word',
            fontWeight: '600',
            fontSize: '16px'
          }}
        >
          Company
        </Typography>
        <Typography
          sx={(theme) => ({
            maxWidth: '100%',
            wordWrap: 'break-word',
            fontWeight: '400',
            fontSize: '14px',
            color: theme.customColors.themeNeutralSecondaryColor
          })}
        >
          {user.company}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            maxWidth: '100%',
            wordWrap: 'break-word',
            fontWeight: '600',
            fontSize: '16px'
          }}
        >
          {/* Check if user email > 1 */}
          {user.emails.length > 1 ? 'Emails' : 'Email'}
        </Typography>
        <Typography
          sx={(theme) => ({
            maxWidth: '100%',
            wordWrap: 'break-word',
            fontWeight: '400',
            fontSize: '14px',
            color: theme.customColors.themeNeutralSecondaryColor
          })}
        >
          {user.emails.map((email, idx) => {
            // Check if emails length > 0 and if is not last index, add trailing commas and space
            return `${email}${user.emails.length > 1 && idx !== user.emails.length - 1 ? ', ' : ''}`;
          })}
        </Typography>
      </Box>
    </Box>
  );

  const LinkedAccountsSection = () => (
    <Box
      sx={{
        width: '100%',
        marginBottom: '50px'
      }}
    >
      <Typography
        sx={{
          maxWidth: '100%',
          wordWrap: 'break-word',
          fontWeight: '600',
          fontSize: '26px',
          marginBottom: '20px'
        }}
      >
        Linked Accounts
      </Typography>
      <SlackAuthComponent
        slackAuthToken={slackCredentials?.access_token}
        userId={user.user_id}
      />
      <NotionAuthComponent
        notionAuthToken={notionCredentials?.access_token}
        userId={user.user_id}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        padding: '10px',
        marginTop: '20px',
        width: '600px',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        alignSelf: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}
    >
      <ProfileSection />
      {user.isManager && <LinkedAccountsSection />}
      {user.isManager && <DocumentIngestion />}
    </Box>
  );
};

export default ProfilePage;
