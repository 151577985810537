import { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress
} from '@mui/material';
import { getAutomationJobs } from '../../api/automationAgent';
import { useNavigate } from 'react-router-dom';
import { AutomationJob } from '../../types/automation';
import { statusStyles } from '../../utils/automation';

const truncateJobId = (jobId: string) => {
  return jobId.slice(0, 8) + '...';
};

const AutomationJobHomeScreen = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState<AutomationJob[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await getAutomationJobs(page * rowsPerPage, rowsPerPage);
      setJobs(response);
    } catch (error) {
      console.error('Failed to fetch automation jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [page, rowsPerPage]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (jobId: string) => {
    navigate(`/automation/job/${jobId}`);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'auto'
      }}
    >
      <TableContainer sx={{}}>
        <Table sx={{ minWidth: 650 }} aria-label="automation jobs table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: 600,
                  backgroundColor: '#f5f5f5',
                  borderBottom: '2px solid #e0e0e0'
                }}
              >
                Job ID
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  backgroundColor: '#f5f5f5',
                  borderBottom: '2px solid #e0e0e0'
                }}
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  backgroundColor: '#f5f5f5',
                  borderBottom: '2px solid #e0e0e0'
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  backgroundColor: '#f5f5f5',
                  borderBottom: '2px solid #e0e0e0',
                  textAlign: 'left'
                }}
              >
                Created At
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <TableRow
                key={job.id}
                hover
                onClick={() => handleRowClick(job.id)}
                sx={{
                  '&:hover': {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer'
                  },
                  '&:nth-of-type(even)': {
                    backgroundColor: '#fcfcfc'
                  }
                }}
              >
                <TableCell sx={{ color: '#666' }}>
                  {truncateJobId(job.id)}
                </TableCell>
                <TableCell>{job.job_description}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor:
                        statusStyles[job.status]?.backgroundColor || '#e0e0e0',
                      color: statusStyles[job.status]?.color || '#000',
                      padding: '4px 12px',
                      borderRadius: '16px',
                      display: 'inline-block',
                      fontSize: '0.875rem'
                    }}
                  >
                    {job.status}
                  </Box>
                </TableCell>
                <TableCell sx={{ color: '#666', textAlign: 'left' }}>
                  {new Date(job.create_ts).toLocaleDateString([], {
                    month: 'short',
                    day: '2-digit'
                  })}{' '}
                  {new Date(job.create_ts).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          borderTop: '1px solid #e0e0e0'
        }}
      />
      {/* </Paper> */}
    </Box>
  );
};

export default AutomationJobHomeScreen;
